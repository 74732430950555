/**
 * @author Connor Manning
 */

export class EptLoader {
	static async load(file, scanMeta, callback, nodeLoadedCallback, headers) {

		const requestHeaders = {
			headers: {
				...headers
			}
		}

		let response = await fetch(file, requestHeaders);
		let json = await response.json();

		let url = file.substr(0, file.lastIndexOf('ept.json'));
		let geometry = new Potree.PointCloudEptGeometry(url, json, scanMeta, nodeLoadedCallback);
		let root = new Potree.PointCloudEptGeometryNode(geometry);

		geometry.root = root;
		geometry.root.load(requestHeaders);

		callback(geometry);
	}
};

