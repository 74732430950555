
const XHRFactory = {
	config: {
		withCredentials: false,
		customHeaders: [
			{ header: null, value: null }
		]
	},

	createXMLHttpRequest: function () {
		let xhr = new XMLHttpRequest();

		if (this.config.customHeaders &&
			Array.isArray(this.config.customHeaders) &&
			this.config.customHeaders.length > 0) {
			let baseOpen = xhr.open;
			let customHeaders = this.config.customHeaders;
			xhr.open = function () {
				baseOpen.apply(this, [].slice.call(arguments));
				customHeaders.forEach(function (customHeader) {
					if (!!customHeader.header && !!customHeader.value) {
						xhr.setRequestHeader(customHeader.header, customHeader.value);
					}
				});
			};
		}

		return xhr;
	},

	setCustomHeader: function(header, value) {
		const index = this.config.customHeaders.findIndex((item) => item.header === header)

		if (index !== -1) {
			this.config.customHeaders[index] = { header, value }
		} else {
			this.config.customHeaders.push({ header, value})
		}
	},

	getCustomHeader: function(header) {
		const index = this.config.customHeaders.findIndex((item) => item.header === header)

		if (index === -1) return null

		return {
			[this.config.customHeaders[index].header]: this.config.customHeaders[index].value
		}
	}
};

class RetryXHR {
	constructor(method, url, callback, configuration = {}) {
		this.method = method;
		this.url = url;
		this.callback = callback
		this.configuration = configuration

		this.retryDelay = 500

		this.sendRequest()
	}

	sendRequest() {
		const xhr = XHRFactory.createXMLHttpRequest();
		xhr.open(this.method, this.url, true);

		if (this.configuration.responseType) xhr.responseType = this.configuration.responseType;
		if (this.configuration.mimeType) xhr.overrideMimeType(this.configuration.mimeType);

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					this.callback(xhr);
				} else {
					console.log(`Attempt to load data from ${this.url} was failed with response status: ${xhr.status}`);

					// If current delay is less than 30 seconds, retrying the request
					if (this.retryDelay <= 30000) {
						console.log(`Retrying to load the data...`);
						setTimeout(() => {
							this.retryDelay += 500;
							this.sendRequest();
						}, this.retryDelay);
					} else {
						console.log(`That was the last attempt to load the data.`);
					}
				}
			}
		};

		xhr.send(null);
	}
}

export {
	XHRFactory,
	RetryXHR
};